<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row">
          <h1>Dispatch Dashboard</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <span class="d-flex flex-row align-center">
              <span class="mr-5">
                <v-text-field hide-details label="Go To Dispatch #" dense outlined @change="goToDocument" v-model="dispatchIdSearch" :loading="goToDocumentLoader"/>
              </span>
              <v-btn color="info" @click="openOtherStatusesDialog">View Other Statuses</v-btn>
<!--              <v-checkbox-->
<!--                  v-model="allBranchesSelected"-->
<!--                  label="All"-->
<!--                  :value="0"-->
<!--                  @click="updateBranchFilter(0)"-->
<!--              />-->
<!--              <span class="d-flex flex-row ml-5">-->
<!--                <v-checkbox-->
<!--                    v-for="(branch, i) of allBranches"-->
<!--                    :key="i"-->
<!--                    v-model="options.selectedBranches"-->
<!--                    :label="branch.label"-->
<!--                    :value="branch.value"-->
<!--                    class="mr-5"-->
<!--                    @click="updateBranchFilter(branch.value)"-->
<!--                ></v-checkbox>-->
<!--              </span>-->
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-card>
            <v-card-text style="padding-left: 9px; padding-right: 1px;">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column mr-2 align-center" :style="'height: 75vh; width: 100%; border: 1px solid grey; border-radius: 7px; padding: 10px;'">
                  <h2 style="min-height: 30px;">Incomplete Orders ({{orders.filter(x=>x.deliveryInfo?.status !== 2).length}})</h2>
                  <span class="text-center">These are all orders that have not been completely delivered.</span>
                  <div class="d-flex flex-column " style="width: 100%; height: 100%; overflow-y: scroll;">
                    <v-card outlined v-for="(order, j) of orders.filter(x=>x.deliveryInfo?.status !== 2)" style="width: 100%" class="mb-2" :key="j" @click="rowClick(order.id)">
                      <v-card-text class="d-flex flex-column">
                        <span class="d-flex flex-row justify-space-between">
                          <b class='mr-2' style="color: purple">Order #{{ order.id }}</b>
                        </span>
                        <div style="font-size: 12px;">{{ utils.formatDate(order.sealedAt) }}</div>
                        <div>{{ order.Customer?(order.Customer.name?order.Customer.name:(order.Customer.firstName||order.Customer.lastName?(`${order.Customer.firstName||''} ${order.Customer.lastName||''}`):'-')):"-" }}</div>
                        <span v-if="order.deliveryInfo.address && Object.keys(order.deliveryInfo.address).length>0" class="d-flex flex-row align-center">
                          <v-icon small class="mr-2">mdi-map-marker</v-icon>
                          <span>
                            <div v-if="order.deliveryInfo.address.city">{{ order.deliveryInfo.address.city }}</div>
                            <div v-else style="font-size: 11px;" class="d-flex flex-column">
                              <span style="line-height: 11px;">{{ order.deliveryInfo.address.line1 }}</span>
                              <span v-if="order.deliveryInfo.address.line2" style="line-height: 11px;">{{ order.deliveryInfo.address.line2 }}</span>
                            </div>
                          </span>
                        </span>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="10">
          <v-card>
            <v-card-text class="">
              <div class="d-flex flex-row">
                <div v-for="(board, i) of boardData.filter(x => !x.onlyShowOnModal && x.data.length>0)" :key="i" class="d-flex flex-column mr-2 align-center" :style="'height: 75vh; width: '+Math.floor(100/boardData.filter(x => !x.onlyShowOnModal && x.data.length>0).length)+'%; border: 1px solid grey; border-radius: 7px; padding: 10px;'">
                  <h2 style="min-height: 60px;">{{ utils.parseDispatchStatus(board.status) }} ({{board.data.length}})</h2>
                  <div class="d-flex flex-column " style="width: 100%; height: 100%; overflow-y: scroll;">
                    <v-card outlined v-for="(dispatch, j) of board.data" style="width: 100%" class="mb-2" :key="j" @click="rowClick(dispatch.oId, dispatch.id)">
                      <v-card-text class="d-flex flex-column">
                        <span class="d-flex flex-row justify-space-between">
                          <b v-if="dispatch.id" class='mr-2'>{{ dispatch.oId }}-{{ dispatch.id }}</b>
                          <b v-else class='mr-2' style="color: rebeccapurple">Order #{{ dispatch.oId }}</b>
                          <div class="align-center">
                            <v-icon small class="mr-2">mdi-package</v-icon> 
                            <span>{{ dispatch.numItems }}</span>
                          </div>
                        </span>
                        <div style="font-size: 12px;">{{ dispatch.boardDate }}</div>
                        <div>{{ dispatch.customer }}</div>
                        <span v-if="dispatch.address && Object.keys(dispatch.address).length>0" class="d-flex flex-row align-center">
                          <v-icon small class="mr-2">mdi-map-marker</v-icon>
                          <span>
                            <div v-if="dispatch.address.city">{{ dispatch.address.city }}</div>
                            <div v-else style="font-size: 11px;" class="d-flex flex-column">
                              <span style="line-height: 11px;">{{ dispatch.address.line1 }}</span>
                              <span v-if="dispatch.address.line2" style="line-height: 11px;">{{ dispatch.address.line2 }}</span>
                            </div>
                          </span>
                        </span>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
        width="90%"
        v-model="otherStatusesDialog.isOpen"
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="d-flex flex-row justify-space-between align-center">
          <span>Other Statuses</span>
          <v-btn text @click="closeOtherStatusesDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-row">
            <div v-for="(board, i) of boardData.filter(x => x.onlyShowOnModal || x.data.length===0)" :key="i" class="d-flex flex-column mr-2 align-center" :style="'height: 75vh; width: '+Math.floor(100/boardData.filter(x => x.onlyShowOnModal || x.data.length===0).length)+'%; border: 1px solid grey; border-radius: 7px; padding: 10px;'">
              <h2 style="min-height: 60px;">{{ utils.parseDispatchStatus(board.status) }} ({{board.data.length}})</h2>
              <div class="d-flex flex-column " style="width: 100%; height: 100%; overflow-y: scroll;">
                <v-card outlined v-for="(dispatch, j) of board.data" style="width: 100%" class="mb-2" :key="j" @click="rowClick(dispatch.oId, dispatch.id)">
                  <v-card-text class="d-flex flex-column">
                    <span class="d-flex flex-row justify-space-between">
                      <b v-if="dispatch.id" class='mr-2'>{{ dispatch.oId }}-{{ dispatch.id }}</b>
                      <b v-else class='mr-2' style="color: rebeccapurple">Order #{{ dispatch.oId }}</b>
                      <div class="align-center">
                        <v-icon small class="mr-2">mdi-package</v-icon>
                        <span>{{ dispatch.numItems }}</span>
                      </div>
                    </span>
                    <div style="font-size: 12px;">{{ dispatch.boardDate }}</div>
                    <div>{{ dispatch.customer }}</div>
                    <span v-if="dispatch.address && Object.keys(dispatch.address).length>0" class="d-flex flex-row align-center">
                      <v-icon small class="mr-2">mdi-map-marker</v-icon>
                      <span>
                        <div v-if="dispatch.address.city">{{ dispatch.address.city }}</div>
                        <div v-else style="font-size: 11px;" class="d-flex flex-column">
                          <span style="line-height: 11px;">{{ dispatch.address.line1 }}</span>
                          <span v-if="dispatch.address.line2" style="line-height: 11px;">{{ dispatch.address.line2 }}</span>
                        </div>
                      </span>
                    </span>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>
#existingSerials{
  border-collapse: collapse;
}
#existingSerials td, #existingSerials th{
  border: 1px solid #ff8080;
  padding: 5px;
}
#existingSerials tbody tr:hover{
  background: #f50000;
  color: white;
}
</style>
<script>
  import axios from 'axios';
  import { mapGetters } from "vuex";
  import utils from "../../plugins/helpers";
  export default {
    data () {
      return {
        utils: utils,

        serialSearch: '',
        serialSearchResults: [],

        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        tableTitle: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Status', value: 'status' },
          { text: 'Date Sealed', value: 'sealedAt' },
          { text: 'Customer', value: 'customerName', sortable: false },
          { text: 'Phone', value: 'customerPhone', sortable: false },
          { text: 'Address', value: 'address', sortable: false },
          { text: 'CSR', value: 'csrUser', sortable: false },
          { text: 'Branch', value: 'branchId', sortable: false },
          { text: '# Items', value: 'itemCount', sortable: false},
        ],
        editMode: false,
        orders: [],

        pagination: {
          loading: false,
          dataCount: 0,
          pageSizeOptions: [5, 10, 15, 30, -1]
        },
        options: {
          search: "",
          sortBy: ['id'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 150,
          selectedBranches: [],
          status: null
        },
        updatingRoute: false,
        init: false,

        allBranches: [],
        allBranchesSelected: true,
        createDispatchLoading: false,

        singular: "Dispatch",
        singularLower: "dispatch",
        plural: "Dispatches",
        pluralLower: "dispatches",
        boardData: [],
        statuses: [
          {text: "All", value: null},
          {text: "New", value: 0},
          {text: "In Progress", value: 1},
          {text: "Completed", value: 2},
          {text: "Voided", value: -1},
        ],

        dispatchStatuses: [
          {text: "New", value: 0, boardDateRef: 'createdAt'},
          {text: "Scheduled", value: 1, boardDateRef: 'scheduledOutAt'},
          {text: "Out for Delivery", value: 2, boardDateRef: 'historyLast'},
          {text: "Attempted Delivery", value: 3, boardDateRef: 'historyLast'},
          {text: "Delayed", value: 4, boardDateRef: 'historyLast'},
          {text: "Delivered", value: 5, boardDateRef: 'completedAt', onlyShowOnModal: true},
          {text: "Voided", value: -1, boardDateRef: 'historyLast', onlyShowOnModal: true},
          {text: "Other", value: -2, boardDateRef: 'createdAt', onlyShowOnModal: true},
        ],

        lineErrors: {
          productId: null,
          errors: []
        },

        otherStatusesDialog: {
          isOpen: false,
          isLoading: false
        },

        dispatchIdSearch: '',
        goToDocumentLoader: false,
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 150
        this.options.sortBy = [oldQuery.sort || "createdAt"]
        this.options.sortDesc = [oldQuery.order || true]
        this.options.search = oldQuery.search || ""
        this.options.selectedBranches = oldQuery.selectedBranches || []
        this.options.status = (oldQuery.status || oldQuery.status===0)?oldQuery.status:null

        if(this.options.selectedBranches.length>0) this.allBranchesSelected = false;

        //used for pagination end

        if(this.getGlobalValue('hiddenDispatchStatusesFromFrontend')){
          let statuses = this.getGlobalValue('hiddenDispatchStatusesFromFrontend').split(",").map(x => x.trim())

          for(let status of statuses){
            let sI = this.dispatchStatuses.findIndex(x => x.text===status);
            if(!sI<0) continue;

            this.dispatchStatuses.splice(sI, 1);
          }
        }
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){
        try {
          this.init = true;

          this.pagination.pageSizeOptions = this.getGlobalValue("paginationPageLimitsGeneral")?.split(",").map(x => parseInt(x)) || this.pagination.pageSizeOptions;

          if(this.isAllowed('dispatch', 'excludeBranchFilter')){
            this.allBranches = this.getBranches.map(x => {
              return {
                label: x.name,
                value: x.id
              }
            });
          }
          else{
            this.allBranches = this.getUser?.metadata?.branchData?.allowed.map(x => {
              return {
                label: this.lookupBranch(x),
                value: x
              };
            });
          }

          await this.getAllData();
          this.init = false;
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
            this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getId', 'getEndpoint', 'lookupUsername', 'getGlobalValue', 'lookupBranch', 'getBranches', 'isAllowed', 'getUser', 'serialNaming']),
    },
    watch: {
      options: {
        async handler () {
          if(this.pagination.loading) return;
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 150
          this.options.sortBy = [oldQuery.sort || "createdAt"]
          this.options.sortDesc = [oldQuery.order || true]
          this.options.search = oldQuery.search || ""
          this.options.selectedBranches = oldQuery.selectedBranches || []
          this.options.status = (oldQuery.status || oldQuery.status===0)?oldQuery.status:null
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        if(oldQuery.selectedBranches){
          if(Array.isArray(oldQuery.selectedBranches)) oldQuery.selectedBranches = oldQuery.selectedBranches.map(x => parseInt(x))
          else oldQuery.selectedBranches = [parseInt(oldQuery.selectedBranches)]
        }
        if(oldQuery.status) oldQuery.status = oldQuery.status || oldQuery.status===0?parseInt(oldQuery.status):null;
        return oldQuery;
      },
      async getAllData(){
        try{
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: false, //this.options.sortDesc[0],
            search: this.options.search,
            selectedBranches: this.options.selectedBranches,
            status: this.options.status
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          //dec 2024 populating board data
          this.boardData = [];
          for(let status of this.dispatchStatuses){
            this.boardData.push({
              data: [],
              status: status.value,
              onlyShowOnModal: status.onlyShowOnModal
            })
          }

          let res = await axios.get(`${this.getEndpoint}/api/dispatch/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.orders = res.data.data.map(x => {
            return {
              ...x,
              isLoading: false
            }
          })

          let today = new Date();
          const offset = today.getTimezoneOffset()
          today = new Date(today.getTime() - (offset*60*1000))
          today = today.toISOString().split('T')[0];

          for(let order of this.orders){
            if(!order.deliveryInfo.dispatches?.length){
              order.deliveryInfo.dispatches = [{oId: order.id, id: "", boardDate: null, status: 0, createdAt: order.sealedAt, numItems: order.itemCount}]
            }

            for(let dispatch of order.deliveryInfo.dispatches){
              if(![0, -1, 5, -2].includes(dispatch.status) && dispatch.scheduledOutAt && dispatch.scheduledOutAt<today){
                dispatch.status = 4;
              }

              let statusCategory = this.boardData.find(x => x.status===dispatch.status);
              if(!statusCategory){
                dispatch.status = -2
                statusCategory = this.boardData.find(x => x.status===dispatch.status);
              }

              dispatch.oId = order.id
              
              let ref = this.dispatchStatuses.find(x=>x.value === dispatch.status).boardDateRef
              if(ref === "historyLast"){
                if(dispatch.history && dispatch.history.length > 0){
                  dispatch.boardDate = utils.formatDate(dispatch.history[dispatch.history.length-1].createdAt, 'withTime')
                }else{
                  dispatch.boardDate = null
                }
              }else{
                dispatch.boardDate = utils.formatDate(dispatch[ref], 'withTime')
              }

              dispatch.customer = order.Customer?(order.Customer.name?order.Customer.name:(order.Customer.firstName||order.Customer.lastName?(`${order.Customer.firstName||''} ${order.Customer.lastName||''}`):'-')):"-"
              if(dispatch.items){
                dispatch.numItems = 0
                for(let item of dispatch.items){
                  dispatch.numItems+=utils.pff(item.quantity)
                }
              }

              statusCategory.data.push(dispatch)
            }
          }

          //used for pagination
          this.pagination.dataCount = res.data.total
          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};

          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;
          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;

              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }

          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              if(this.$route.path===`/dispatch`) await this.$router.replace({path: `/dispatch`, query: newQuery});
            }
            else{
              if(this.$route.path===`/dispatch`) await this.$router.push({path: `/dispatch`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      },
      updateBranchFilter(id){
        if(id===0){
          if(this.allBranchesSelected){ //the value is already being set true by the component
            this.options.selectedBranches = [];
          }
          else{
            this.options.selectedBranches = this.allBranches.map(x => x.value);
          }
        }
        else{
          if(this.options.selectedBranches.length===0) this.allBranchesSelected = true;
          else this.allBranchesSelected = false;
        }
      },
      goToDocument(){
        try{
          if(!this.dispatchIdSearch?.trim()) return;

          this.goToDocumentLoader = true

          let doc = this.dispatchIdSearch.split("-");

          if(isNaN(doc[0]) || !Number.isInteger(parseInt(doc[0]))) throw "Invalid Dispatch ID";

          this.rowClick(doc[0], doc.length>1?doc[1]:null);

        } catch (error) {
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.goToDocumentLoader = false;
        }
      },
      rowClick(oid, did=null){
        if(!did){
          this.$router.push({ path: `/dispatch/view/${oid}`})
        }else{
          this.$router.push({ path: `/dispatch/view/${oid}?curr=${did}`})
        }
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      openOtherStatusesDialog(){
        this.otherStatusesDialog.isOpen = true;
      },
      closeOtherStatusesDialog(){
        this.otherStatusesDialog.isOpen = false;
      }
    }
  }
</script>
